import { ModelBase } from "./ModelBase";

export interface DataSourceBase<TId> extends ModelBase<TId> {
  /**
   * Unique code to distinguish a data source.
   * Will not always be set due to custom data sources.
   */
  code?: DataSourceCode;
  name: string;
  url?: string;
  description?: string;
  type: DataSourceType;
  enabled?: boolean;
  fileImportStatus?: ImportStatus;
  fileImportInstructions?: string;
  syncImportStatus?: ImportStatus;
  syncImportInstructions?: string;
  syncImportParams?: SyncImportParamsBase;
  syncAuthType?: "oauth" | "vezgo";
  image?: string;
  mappedToCode?: DataSourceCode;
  /** used to lookup information and mappings about this datasource on Crypto Compare */
  ccInternalName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  networks?: Pick<DataSourceBase<TId>, "_id">[];
}

export interface SyncImportParamsBase {
  key: ImportParamBase;
  secret?: ImportParamBase;
  passphrase?: ImportParamBase;
}

export interface ImportParamBase {
  text: string;
  order: number;
}

export enum DataSourceType {
  Exchange = "exchange",
  Blockchain = "blockchain",
  Wallet = "wallet",
  Custom = "custom",
  Import = "import",
}

// data source names
export enum DataSourceCode {
  // exchanges
  AmberApp = "amberapp",
  Ascendex = "ascendex",
  Bamboo = "bamboo",
  Binance = "Binance",
  BingX = "bingx",
  Bitaroo = "Bitaroo",
  Bitbns = "Bitbns",
  Bitfinex = "Bitfinex",
  Bitget = "bitget",
  Bitkub = "Bitkub",
  BitMart = "BitMart",
  BitMEX = "Bitmex",
  Bitrue = "bitrue",
  Bitstamp = "Bitstamp",
  BitTrade = "bit_trade",
  BitTrex = "BitTrex",
  BlockFi = "BlockFi",
  BTCE = "BTCE",
  BtcMarkets = "BTCMarkets",
  Bybit = "bybit",
  CalebBrown = "caleb_and_brown",
  Celsius = "Celsius",
  Coinbase = "Coinbase",
  CoinbasePro = "coinbase_pro",
  CoinEx = "CoinEx",
  CoinHarbour = "CoinHarbour",
  CoinJar = "CoinJar",
  CoinJarExchange = "CoinJarExchange",
  Cointree = "Cointree",
  CoinSpot = "CoinSpot",
  CoinStash = "CoinStash",
  CryptoDotCom = "Crypto.com",
  CryptoDotComExchange = "crypto_com_exchange",
  CryptoSpend = "cryptospend",
  DeltaExchange = "delta_spot",
  DigitalSurge = "DigitalSurge",
  Elbaite = "elbaite",
  EToro = "etoro",
  Finblox = "finblox",
  FinderEarn = "finder_earn",
  FTX = "FTX",
  Gateio = "Gateio",
  Gemini = "Gemini",
  GPIB = "gpib",
  HardBlock = "hardblock",
  HitBTC = "HitBTC",
  HTX = "huobi",
  IGMarkets = "ig_markets",
  IndependentReserve = "IndependentReserve",
  KinesisMoney = "kinesis_money",
  Kraken = "Kraken",
  KuCoin = "KuCoin",
  Ledn = "ledn",
  LROS = "living_room_of_satoshi",
  Luno = "Luno",
  MEXC = "mexc",
  MoonPay = "moonpay",
  Nexo = "nexo",
  NiceHash = "NiceHash",
  OKX = "OKEX",
  Paxful = "paxful",
  Phemex = "phemex",
  Pionex = "pionex",
  Poloniex = "Poloniex",
  PrimeXBT = "primexbt",
  SouthXChange = "south_xchange",
  Swyftx = "Swyftx",
  Uphold = "uphold",
  Virgo = "virgo",
  Wealth99 = "wealth99",
  WooX = "wootrade",
  XTDotCom = "xtpub",
  YieldApp = "yield_app",
  YouHodler = "you_hodler",

  // wallets
  Atomic = "atomic_wallet",
  Daedalus = "daedalus",
  Etherscan = "Etherscan",
  Exodus = "Exodus",
  Ledger = "Ledger",
  Metamask = "Metamask",
  Mintscan = "mintscan",
  MyEtherWallet = "MyEtherWallet",
  Qoin = "qoin",
  Trezor = "trezor",
  Vera = "Vera",
  Xaman = "xaman",
  Yoroi = "yoroi",

  // blockchains
  Algorand = "algorand",
  Arbitrum = "arbitrum",
  Avalanche = "avalanche",
  Beam = "beam",
  Bitcoin = "Bitcoin",
  BitcoinCash = "bitcoinCash",
  Blast = "blast",
  Chiliz = "chiliz_chain",
  Cyber = "cyber",
  Elastos = "elastos",
  ECash = "eCash",
  Litecoin = "litecoin",
  Dash = "dash",
  DFK = "defi_kingdoms_chain",
  Dogecoin = "dogecoin",
  Base = "base",
  BSC = "binance_smart_chain",
  Cardano = "Cardano",
  Celo = "celo",
  Cronos = "cronos_evm",
  Ethereum = "Ethereum",
  Flare = "flare",
  Fraxtal = "fraxtal",
  FTM = "fantom",
  Gnosis = "gnosis_chain",
  Helium = "Helium",
  Mantle = "mantle",
  Metis = "metis",
  Mint = "mint_chain",
  Mode = "mode_network",
  Moonbeam = "moonbeam",
  MultiversX = "multiversx",
  Optimism = "optimism",
  Polkadot = "polkadot",
  Polygon = "polygon",
  Solana = "solana",
  Scroll = "scroll",
  Songbird = "songbird",
  Stacks = "stacks",
  Stellar = "stellar",
  Sui = "sui",
  Taiko = "taiko",
  Tezos = "tezos",
  Tron = "tron",
  XRP = "xrp",
  ZkSyncEra = "zksync_era",
  Zora = "zora_chain",

  // others
  OpeningBalances = "sylaOpeningBalances",
  Koinly = "koinly",
  CoinTracking = "cointracking",
}

export enum ImportStatus {
  Off = "off",
  Alpha = "alpha",
  Beta = "beta",
  Gold = "gold",
}

export interface DataSourceSupportedInputs {
  sync: ImportStatus | undefined;
  file: ImportStatus | undefined;
}
export const getDataSourceSupportedInputs = ({
  fileImportStatus,
  syncImportStatus,
}: {
  fileImportStatus?: ImportStatus;
  syncImportStatus?: ImportStatus;
}): DataSourceSupportedInputs => ({
  file: (fileImportStatus ?? "off") == "off" ? undefined : fileImportStatus,
  sync: (syncImportStatus ?? "off") == "off" ? undefined : syncImportStatus,
});
